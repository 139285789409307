<template>
  <div>
    <h1 class="my-4">Отзывы на мой аккаунт</h1>

    <div class="d-flex flex-column-reverse flex-md-column">
      <div
        v-if="
          !currentUser ||
          !currentUser.blogger_profile ||
          !currentUser.services_subscriber ||
          !currentUser.blogger_profile.filled
        "
        class="d-flex flex-wrap flex-md-nowrap mt-2 mb-5"
      >
        <div class="hovering__pad w-100 w-lg-50 p-4 mr-lg-5">
          <div class="step-bullet fs-18" :bullet-value="1">
            Теперь вы можете увидеть отзывы, которые оставляли на вас заказчики на сайте
            Easyprbot.com. Даже непринятые модерацией или находящиеся на модерации.
          </div>
          <div class="step-bullet mt-5 fs-18" :bullet-value="2">
            Если отзыв был отклонен из-за скриншотов, вы сможете загрузить их вместо заказчика и
            отправить на модерацию заново.
          </div>
          <div class="step-bullet fw-500 mt-5 fs-18" :bullet-value="3">
            Чтобы увидеть отзывы на себя, загрузите вашу карточку блогера и оформите подписку.
          </div>
          <div class="mt-3 d-flex flex-wrap myrevbtns">
            <b-button
              href="https://sub.easyprbot.com/"
              class="w-100 w-sm-auto mr-sm-4 mt-3 ph-42"
              variant="yellow"
              >Оформить подписку</b-button
            >
            <b-button
              :to="{ name: 'fill_statistics' }"
              class="mt-3 w-100 w-sm-auto ph-42"
              variant="outline-default"
              >Загрузить мою карточку</b-button
            >
          </div>
        </div>
        <!--div class="hovering__pad w-100 w-md-50 p-4 mt-5">
          <div>
            <img
              class="mr-3 d-inline-block"
              style="width: 35px; height: 23px"
              src="@main/assets/img/icons/ut_icon.png"
            />
            <span style="font-size: 16px">Как работает сервис?</span>
          </div>
        </!--div-->
      </div>
      <blogger-sub-warning class="mb-5"></blogger-sub-warning>
    </div>
    <reviews-table
      ref="revTable"
      :mapFunc="
        (review) =>
          reviewsOptions.groups.ON_MODERATION_STATUSES.concat(
            reviewsOptions.groups.REFUSED_STATUSES
          ).includes(review.moderation_status)
            ? {
                id: review.id,
                date: review.date,
                moderation_status: review.moderation_status,
                customer: review.customer,
                refuse_reasons: review.refuse_reasons,
              }
            : review
      "
      :fieldOrder="['date', 'customer', 'emptystatus']"
      :hidelike="true"
      :excludedFields="['liked', 'text_display', 'advertiser', 'refuse_reasons']"
      :segmentationFunc="reviewsSegmentationFunc"
      :sample="sample && !filterParams.search"
      v-if="filterParams"
      v-model="filterParams"
      ><template #upper-header="{ segmentation, currentSegment, sampled }">
        <div class="upper-part flex-wrap align-items-center">
          <div class="left-part w-100 w-md-75">
            <h1 class="left-part__title rev-title d-block d-llg-inline-block mb-2 mb-md-1">
              {{
                sampled
                  ? 'Пример таблицы с результатами модерации отзывов'
                  : 'Таблица с результатами модерации отзывов'
              }}
            </h1>
          </div>
          <div class="right-part only-desktop only-desktop--flex">
            <span class="gray-text">Показывать по</span>
            <div class="select-wrapper">
              <select class="select" v-model="filterParams.page_size">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div>
            <b-button
              class="mr-3 my-2 my-md-3"
              :disabled="!segment.items.length"
              :variant="currentSegment === segment.name ? 'primary' : 'outline-default'"
              @click="$refs.revTable.setSegment(segment.name)"
              :key="segment.name"
              v-for="segment in segmentation"
              >{{ segment.text }}: {{ segment.amount }}</b-button
            >
          </div>
          <b-input-group class="custom-search-field mt-3 mt-md-0">
            <b-form-input
              v-model.lazy="filterParams.search"
              type="text"
              placeholder="Поиск по нику заказчика"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="$refs.revTable.updateData()" variant="outline-default"
                ><b-icon icon="search"
              /></b-button>
            </b-input-group-append>
          </b-input-group>
        </div> </template
    ></reviews-table>
  </div>
</template>

<script>
import reviewsApi from '@main/api/reviews';
import { mapState, mapMutations, mapActions } from 'vuex';
import ReviewsTable from '../ReviewsTable.vue';
import BloggerSubWarning from '../../ad/warnings/BloggerSubWarning.vue';

export default {
  components: { ReviewsTable, BloggerSubWarning },
  data: () => ({
    filterParams: null,
  }),
  computed: {
    ...mapState(['currentUser', 'reviewsOptions']),
  },
  methods: {
    ...mapActions('notifications', ['setWindow']),
    reviewsSegmentationFunc(reviews) {
      if (!reviews) {
        return;
      }
      const intermediateReviews = reviews.filter((val) =>
        this.reviewsOptions.groups.ON_MODERATION_STATUSES.includes(val.moderation_status)
      );
      const acceptedReviews = reviews.filter((val) =>
        this.reviewsOptions.groups.ACCEPTED_STATUSES.includes(val.moderation_status)
      );
      const declinedReviews = reviews.filter((val) =>
        this.reviewsOptions.groups.REFUSED_STATUSES.includes(val.moderation_status)
      );
      return [
        { name: 'all', text: 'Все отзывы', amount: reviews.length, items: reviews },
        {
          name: 'intermediate',
          text: 'На модерации',
          amount: intermediateReviews.length,
          items: intermediateReviews,
        },
        {
          name: 'accepted',
          text: 'Опубликован',
          amount: acceptedReviews.length,
          items: acceptedReviews,
        },
        {
          name: 'declined',
          text: 'Отклонен',
          amount: declinedReviews.length,
          items: declinedReviews,
        },
      ];
    },
    async sample() {
      const res = await reviewsApi.getBloggerSample();
      return res;
    },
  },

  mounted() {
    this.filterParams = {
      ...this.lodash.cloneDeep(reviewsApi.defaultFilterParams),
      mode: 'blogger',
      customer_kind: '',
      page_size: 200,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 550px) {
  .myrevbtns {
    padding-left: 48px;
  }
}
</style>
