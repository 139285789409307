<template>
  <div
    v-if="!currentUser || !currentUser.blogger_profile || !currentUser.blogger_profile.filled"
    class="hovering__pad px-3 pb-4 d-lg-flex justify-content-between align-items-center"
  >
    <h2 class="mt-4 mb-3 mb-md-0 d-md-inline-block">
      ❗{{
        currentUser && currentUser.blogger_profile && !currentUser.blogger_profile.filled
          ? 'Заполните до конца'
          : 'Загрузите'
      }}
      вашу карточку{{
        !currentUser || !currentUser.services_subscriber ? ' и оплатите подписку' : ''
      }}, чтобы использовать сервис
    </h2>
    <b-button
      @click="onCardLoadClick"
      variant="yellow"
      style="flex: 1"
      class="mt-4 ph-42 w-100 w-md-none sbtn d-md-inline-block ml-llg-3"
      >Загрузить карточку блогера</b-button
    >
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState(['currentUser']),
  },
  methods: {
    async onCardLoadClick() {
      if (!this.currentUser || !this.currentUser.blogger_profile) {
        this.$router.push({ name: 'fill_statistics' });
        return;
      }
      this.$router.push({ path: '/settings/payments/' });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .sbtn {
    max-width: 250px !important;
  }
}
</style>
